import { Component, OnInit } from '@angular/core';
import { RegistroService } from '../../servicios/registro.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

	public datos : Array<any>;
  public bodegas : Array<any>;
  public datos_nt : Array<any>;

  constructor(
  	public _regS : RegistroService
  	) { 
  	this.datos = [];
    this.bodegas = [];
    this.datos_nt = [];
  	 }

  ngOnInit() {

    this._regS.getBodega().subscribe(
      response =>{

        for (var i = 0; i < response.data.length; ++i) {
           this.bodegas.push(response.data[i]);
        }

      },error=>{

      }
      );

  	this._regS.getToma().subscribe(
  		response=>{

  			for (var i = 0; i < response.data.length; ++i) {
				this.datos.push(response.data[i]);  				
  			}

  		},error=>{
  			console.log(error);
  		}
  		);

  }


  onChangeFecha(fecha, bodega){

  	console.log(fecha);
    console.log(bodega);

    if(fecha != ''){

  	this._regS.getTomaF(fecha, bodega).subscribe(
  		response=>{
  			console.log(response);
  			this.datos = [];
  			for (var i = 0; i < response.data.length; ++i) {
				this.datos.push(response.data[i]);  				
  			}

  		},error=>{
  			console.log(error);
  		}
  		);

    this._regS.getNoTomaF(fecha, bodega).subscribe(
      response=>{
        console.log(response);
        this.datos_nt = [];
        for (var i = 0; i < response.data.length; ++i) {
        this.datos_nt.push(response.data[i]);          
        }

      },error=>{
        console.log(error);
      }
      );

    }else{

    }
  }

}
