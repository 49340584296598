import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistroService } from '../../servicios/registro.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

	public formU : FormGroup;
	public mensaje_info : string;
	public bodegas : Array<any>;
	public usuarios : Array<any>;

  constructor(
  	private formBuilder: FormBuilder,
  	private _regS : RegistroService
  	) {
  	this.mensaje_info = '';

  	this.bodegas = [];
  	this.usuarios = [];
  	 }

  ngOnInit() {

	this.cargarUsuarios();  	

  	this._regS.getBodega().subscribe(
  		response=>{

  			for (var i = 0; i < response.data.length; ++i) {
  				this.bodegas.push(response.data[i]);
  			}

  		},error=>{
  			console.log(error);
  		}
  	);

  	this.formU = this.formBuilder.group({
  		user : ['', Validators.required],
  		pass : ['', Validators.required],
  		rol : ['', Validators.required],
  		bodega : ['', Validators.required]
  	});

  }

  get fU() { return this.formU.controls; }

  cargarUsuarios(){

  	this.usuarios = [];

  	this._regS.getUsuarios().subscribe(
  		response=>{

  			console.log(response);

  			for (var i = 0; i < response.data.length; ++i) {
  				this.usuarios.push(response.data[i]);
  			}

  		},error=>{
  			console.log(error);
  		}
  		);
  }

  onRegistro(){

  	let datos = {
  		usuario : this.fU.user.value,
  		clave : this.fU.pass.value,
  		rol : this.fU.rol.value,
  		bodega : this.fU.bodega.value
  	}


  	this._regS.registrarUsuario(datos).subscribe(
  		response =>{
  			console.log(response);
  			this.mensaje_info = response.message;

  			this.formU.reset();

  			this.cargarUsuarios();

  		},error=>{
  			console.log(error);
  		});

  }

}
