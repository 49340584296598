declare const $ : any;
import { Component, OnInit } from '@angular/core';
import { RegistroService} from '../../servicios/registro.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css']
})


export class HistoricoComponent implements OnInit {

	public referencias : Array<any>;
	public filtro : boolean;
	public referencia_select : Array<any>
	public referencia_selectB : Array<any>
	public lineChart = [];
	public barChart = [];
	public bodegas : Array<any>;

	public grafico1 : string;
	public grafico2 : string;

  constructor(
  	private _regS : RegistroService
  	) { 
  	this.referencias = [];
  	this.filtro = false;
  	this.referencia_select = [];
  	this.referencia_selectB = [];
  	this.bodegas = [];
  	this.grafico1 ='';
  	this.grafico2 ='';
  }

  ngOnInit() {

  	this._regS.getReferenciasHis().subscribe(
  		response=>{

  			for (var i = 0; i < response.data.length; ++i) {
  				this.referencias.push(response.data[i]);
  			}

  		},error=>{

  		}
  		);

  	this._regS.getBodega().subscribe(
  		response=>{

  			console.log(response.data);

  			for (var i = 0; i < response.data.length; ++i) {
  				this.bodegas.push(response.data[i].bodega);
  			}

  			console.log(this.bodegas);

  		},error=>{
  			console.log(error);
  		}
  	);

  }

  filtrar(codigo : string){
  	this.filtro = true;

  	console.log(codigo);

  	this._regS.getReferenciasHisSel(codigo).subscribe(
  		response=>{

  			
  			console.log(response);
  			this.referencia_select = [];
  			for (var i = 0; i < response.data.length; ++i) {
  			this.referencia_select.push(response.data[i]);
  			}

  			// this.grafico();
  			// this.graficoBar();

  		},error=>{
  			console.log(error);
  		}
  		);

  }

  filtrarGrafico(bodega : string){

  	let codigo = this.referencia_select[0].codigo;

  	this.removeCanvas();

  	this._regS.getReferenciasHisSelBod(codigo, bodega).subscribe(
  		response=>{

  			this.referencia_selectB = [];
  			for (var i = 0; i < response.data.length; ++i) {
  			this.referencia_selectB.push(response.data[i]);
  			}

  			this.grafico();
  			this.graficoBar();

  		},error=>{
  			console.log(error);
  		}
  		);

  }

  noFiltro(){
  	this.filtro = false;
  }


  grafico()
  {



  	var toma = [];
  	var fecha = [];
  	var dms = [];

  	for (var i = 0; i < this.referencia_selectB.length; ++i) {
  		toma.push(this.referencia_selectB[i].cantidad);
  		fecha.push(this.referencia_selectB[i].f_dms);
  		dms.push(this.referencia_selectB[i].stock);
  	}

  	this.lineChart = new Chart('lineChart', {
  		type: 'line',
  		data:{
  			labels : fecha,
  			datasets : [{
  				label: 'Stock Toma',
  				data : toma,
  				fill: false,
  				lineTension: 0.2,
  				borderColor: 'red',
  				borderWidth: 1
  			},
  			{
  				label: 'Stock DMS',
  				data : dms,
  				fill: false,
  				lineTension: 0.2,
  				borderColor: 'blue',
  				borderWidth: 1
  			},
  			]
  		},
  		options: {
  			title: {
  				text: 'Comportamiento Stock Aceites Lineal',
  				display : true
  			},
  			scales: {
  				yAxes : [{
  					ticks : {
  						beginAtZero : true
  					}
  				}]
  			}
  		}
  	});


  }


  graficoBar(){



  	var toma = [];
  	var fecha = [];
  	var dms = [];

  	for (var i = 0; i < this.referencia_selectB.length; ++i) {
  		toma.push(this.referencia_selectB[i].cantidad);
  		fecha.push(this.referencia_selectB[i].f_dms);
  		dms.push(this.referencia_selectB[i].stock);
  	}


  	this.barChart = new Chart('barChart', {
  		type: 'bar',
  		data:{
  			labels : fecha,
  			datasets : [{
  				label: 'Stock Toma',
  				data : toma,
  				backgroundColor : 'red',
  				borderWidth: 1
  			},
  			{
  				label: 'Stock DMS',
  				data : dms,
  				backgroundColor : 'blue',
  				borderWidth: 1
  			},
  			]
  		},
  		options: {
  			title: {
  				text: 'Comportamiento Stock Aceites en Barras',
  				display : true
  			},
  			scales: {
  				yAxes : [{
  					ticks : {
  						beginAtZero : true
  					}
  				}]
  			}
  		}
  	});

  	console.log(this.barChart);

  	this.barChart = [];

  	console.log(this.barChart);
  }

  removeCanvas(){
  $('#lineChart').remove(); // this is my <canvas> element
  $('#barChart').remove();
  $('#canvas1').append('<canvas id="lineChart"><canvas>');
  $('#canvas2').append('<canvas id="barChart"><canvas>');
  }


}
