import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from './GLOBAL';


@Injectable({
  providedIn: 'root'
})
export class RegistroService {

	private url : string;

  constructor(public  _http : Http) {
  	this.url = GLOBAL.url;
  }


 getReferencias(bodega : string)
  {
  	return this._http.get(this.url+'datos-inv/'+bodega).pipe(map(resp=>resp.json()));
  }


  getReferenciasHis()
  {
    return this._http.get(this.url+'referencias').pipe(map(resp=>resp.json()));
  }

  getReferenciasHisSel(cod : string)
  {
    return this._http.get(this.url+'referencias/'+cod).pipe(map(resp=>resp.json()));
  }

    getReferenciasHisSelBod(cod : string, bodega : string)
  {
    return this._http.get(this.url+'referencias/'+cod+'/'+bodega).pipe(map(resp=>resp.json()));
  }

   getToma()
  {
  	return this._http.get(this.url+'datos-toma').pipe(map(resp=>resp.json()));
  }

  getBodega()
  {
    return this._http.get(this.url+'bodegas').pipe(map(resp=>resp.json()));
  }

   getTomaF(fecha : string, bodega : string)
  {
  	return this._http.get(this.url+'datos-toma/'+fecha+'/'+bodega).pipe(map(resp=>resp.json()));
  }

   getNoTomaF(fecha : string, bodega : string)
  {
    return this._http.get(this.url+'datos-no-toma/'+fecha+'/'+bodega).pipe(map(resp=>resp.json()));
  }


   getUsuarios()
  {
     return this._http.get(this.url+'usuarios').pipe(map(resp=>resp.json()));
  }

 registrarDatos(parametros){

 		let json = JSON.stringify(parametros);
		let params = 'json='+json;
		let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

		return this._http.post(this.url+'registro', params, {headers: headers})
						 .pipe(map(res => res.json()));

 }


  registrarUsuario(parametros){

     let json = JSON.stringify(parametros);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

    return this._http.post(this.url+'registro-usuario', params, {headers: headers})
             .pipe(map(res => res.json()));

 }

}
