import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ColisionService } from '../../../servicios/colision.service';

@Component({
  selector: 'app-gestor-repuestos',
  templateUrl: './gestor-repuestos.component.html',
  styleUrls: ['./gestor-repuestos.component.css', '../gestor-aseguradora/gestor-aseguradora.component.css']
})
export class GestorRepuestosComponent implements OnInit {

public pedido_mod : boolean;
public imprevistos : boolean;
public propios : boolean;
public importacion : boolean;
public ped : string;
public msg : string;
public nivel : number;
public pedidos : Array<any>;
public traslados : Array<any>;

public estado_ped : string;

public estado_tras : string;


  constructor(
    private _cS : ColisionService
    ) {
  	this.pedido_mod = true;
    this.imprevistos = false;
    this.propios = false;
    this.importacion = false;
    this.ped = '';
    this.msg = '';
    this.nivel = 0;
    this.pedidos = [];
    this.traslados = [];

    this.estado_ped = '';
    this.estado_tras = '';
   }

  ngOnInit() {
  }


  cambio(valor : boolean){
  	this.pedido_mod = valor;
  }

  cargarOrde(orden : string){

    this.msg = '';
    this.nivel = 0;

    this._cS.getOrdenesAseguradora(orden).subscribe(
      response=>{

          if(response.code == 200){

            if(response.data.length > 0){

              if(response.data[0].radicado != null){

                this.nivel = 1;
                this.msg = 'Esta Orden se encuentra radicada correctamente';

                this.pedidos = [];
                this.traslados = [];

                this.estado_ped = '';
                this.estado_tras = '';

                this.consultarPedidosSistema(orden);
                this.consultarTrasladosSistema(orden);

              }else{
                this.nivel = 2;
                this.msg = 'Esta orden aun no tiene Radicado de aprobación por parte de la aseguradora...';
              }

            }else{
              this.nivel = 3;
              this.msg = 'A esta orden no se le ha realizado el proceso de Aseguradora...';
            }

          }

      },error=>{}
      );

  }


  consultarPedidosSistema(orden : string){

    this._cS.getPedidos(orden).subscribe(
          response=>{
            console.log(response);
            if(response.code == 200){
              if(response.data.length > 0){

                  for (var i = 0; i < response.data.length; ++i) {
                    this.pedidos.push(response.data[i]);
                    this.estado_ped = 'OK';
                  }
                //console.log(this.pedidos);
              }
            }else{
              console.log("no se encontro nada");
            }

          },error=>{
            console.log(error);
          }
      );

  }

  consultarTrasladosSistema(orden : string){

    this._cS.getTraslados(orden).subscribe(
          response=>{
            console.log(response);
            if(response.code == 200){
              if(response.data.length > 0){

                  for (var i = 0; i < response.data.length; ++i) {
                    this.traslados.push(response.data[i]);

                    this.estado_tras = 'OK';
                  }
                //console.log(this.pedidos);
              }
            }else{
              console.log("no se encontro nada");
            }

          },error=>{
            console.log(error);
          }
      );

  }


  guardarInforme(orden : string){

     let datos = {
       orden : orden,
       pedido : this.estado_ped,
       traslado : this.estado_tras
     }


    this._cS.registrarRepuestos(datos).subscribe(
        response=>{
          if(response.code==200){
             console.log(response.message);
          }
        },error=>{
          console.log(error);
        }
      );
  }

}
