import { Component, OnInit } from '@angular/core';
import { ColisionService } from '../../../servicios/colision.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent implements OnInit {




	public datos : Array<any>;
  public estados : Array<any>;
  public orden_select : Array<any>;
  public msg : string;

  constructor(
  	private _cS : ColisionService,
  	private route : Router
  	) {

  	this.datos = [];

    this.estados = [];
    this.orden_select = [];
    this.msg = '';

  	 }

  ngOnInit() {

this.cargarOrdenes();
//this.cargarEstados();
  	

  }


  cargarOrdenes(){
  	this._cS.getOrdenes().subscribe(
  		response=>{

  			if(response.code == 200){

  				for (var i = 0; i < response.data.length; ++i) {
  					this.datos.push(response.data[i]);            
           }


  			}

  		},error=>{
  			console.log(error);
  		}
  		);
  }


  buscarOrden(orden : string){

    this.orden_select = [];

    if(orden == ''){
      this.msg = 'Por favor ingrese un numero de orden para realizar la bsuqueda';
    }else{

     this.msg = '';
      
    this._cS.getOrdenSelect(orden).subscribe(
      response=>{

        console.log(response);

        if(response.code == 200){

           
           if(response.data.length == 0){
             this.msg = 'No se encontro la orden #'+orden;
           }

          for (var i = 0; i < response.data.length; ++i) {
            this.orden_select.push(response.data[i]);            
           }
           console.log(this.orden_select);

        }

      },error=>{
        console.log(error);
      }
      );
  }
  }


   cargarEstados(){
    this._cS.getEstados().subscribe(
      response=>{

        if(response.code == 200){

          for (var i = 0; i < response.data.length; ++i) {
            this.estados.push(response.data[i]);            
           }

         //  console.log(this.estados);


        }

      },error=>{
        console.log(error);
      }
      );
  }


  cargarTraza(or : string){

  	this.route.navigate(['/trazabilidad'],
         {queryParams: {orden: or}});

  }

}
