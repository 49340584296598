import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

 public rol : string;


  constructor(
  	private route : Router
  	) { }

  ngOnInit() {

  	this.rol = localStorage.getItem('rol');

  }

  logout(){
  	localStorage.clear();
  	this.route.navigate(['/login']);

  }

}
