import { Component, OnInit } from '@angular/core';
import { ColisionService} from '../../../servicios/colision.service';
import { Informe } from './informe';

@Component({
  selector: 'app-gestor-aseguradora',
  templateUrl: './gestor-aseguradora.component.html',
  styleUrls: ['./gestor-aseguradora.component.css']
})
export class GestorAseguradoraComponent implements OnInit {

  public mO_estado : string;
  public R_estado : string;
  public datos : Array<any>;
  public documentos : Array<string>;

  public c_asegurado : boolean;
  public c_conductor : boolean;
  public t_propiedad : boolean;
  public l_conduccion : boolean;
  public croquis : boolean;

  public msg : string;

  public informe : any;

  constructor(
    private _cS : ColisionService
    ) {

  	this.mO_estado = '';
    this.datos = [];
    this.documentos = [];
   

    this.c_asegurado = false;
    this.c_conductor = false;
    this.t_propiedad = false;
    this.l_conduccion = false;
    this.croquis = false;

    this.msg = '';
    }

   

  ngOnInit() {


      this.informe = {
      orden : '',
      siniestro : '',
      estado_mo : '',
      estado_re : '',
      fecha_mo : '',
      fecha_re : '',
      fecha_solicitud_aseguradora : '',
      radicado : '',
      observacion : ''
    }
  }

  estadosMO(valor : string){

  	this.mO_estado = valor;
    this.informe.estado_mo = valor;

  }

    estadosR(valor : string){

    this.R_estado = valor;
    this.informe.estado_re = valor;

  }


  buscarOrden(orden : string){

    this.datos = [];
    this.R_estado = '';
    this.mO_estado = '';
    this.msg = '';

    this._cS.getOrden(orden).subscribe(
        response=>{
          if(response.code == 200){
            this.datos = [response.data[0]];
      
            this.documentos = [];

            this.c_asegurado = false;
            this.c_conductor = false;
            this.t_propiedad = false;
            this.l_conduccion = false;
            this.croquis = false;

            this.informe = {
            orden : orden,
            siniestro : '',
            estado_mo : '',
            estado_re : '',
            fecha_mo : '',
            fecha_re : '',
            fecha_solicitud_aseguradora : '',
            radicado : '',
            observacion : ''
    }

            this.getInformes(orden);

            this.obtenerDocumentos(orden);

          }

        },error=>{

        }

      );

  }


  documento(documento : string, estado : boolean){

    console.log(estado);

    if(estado){
      this.documentos.push(documento);
    }else{
      let indice = this.documentos.indexOf(documento);
      this.documentos.splice(indice,1);
    }

    console.log(this.documentos);

  }

  getInformes(orden : string){

    this._cS.getInformes(orden).subscribe(

      response=>{

        console.log(response);

        if(response.code == 200 && response.data.length > 0){
          this.informe = response.data[0];

          console.log(this.informe);

            this.mO_estado = this.informe.estado_mo;
            this.R_estado = this.informe.estado_re;
        }

      },error=>{
        console.log(error);
      }

      );

  }

  guardarInforme(){
    //console.log(this.informe);

    if(this.informe.siniestro == ''){
      this.msg = 'Numero de siniestro necesario para realizar el registro';
    }else if(this.informe.fecha_solicitud_aseguradora == '' || this.informe.orden == ''){
          this.msg = 'La fecha de la solicitud a la aseguradora es obligatoria';
    }else if(this.mO_estado == 'Aprobada' && (this.informe.fecha_mo == '' || this.informe.fecha_mo == null) || this.R_estado == 'Aprobada' && (this.informe.fecha_re == '' || this.informe.fecha_re == null ))
    {
          this.msg = 'Al rechazar o Aprobar Mano de Obra y/o Repuestos sus fechas son de caracter obligatorio!';
    }else{

    this._cS.registrarDatos(this.informe).subscribe(
      response=>{

        console.log(response);

        if(response.code == 200){

          console.log(response.message);
          this.msg = 'Registro almacenado correctamente';
        }else{
          console.log(response.message);
        }

      },error=>{
        console.log(error);
      }
      );


    this.guardarDocumentos();
    }
  }


  obtenerDocumentos(orden){

    this._cS.getDocumentos(orden).subscribe(
      response=>{

        if(response.code == 200){


          for (var i = 0; i < response.data.length; ++i) {
              
            let datos = response.data[i].documento;

            if(datos == 'Cedula del Asegurado'){
              this.c_asegurado = true;
              this.documentos.push(datos);
            }

            if(datos == 'Cedula del Conductor'){
              this.c_conductor = true;
              this.documentos.push(datos);
            }

            if(datos == 'Tarjeta de propiedad'){
              this.t_propiedad = true;
              this.documentos.push(datos);
            }

            if(datos == 'Licencia de conducción'){
              this.l_conduccion = true;
              this.documentos.push(datos);
            }

            if(datos == 'Croquis'){
              this.croquis = true;
              this.documentos.push(datos);
            }



          }

          console.log(this.documentos);

        }

      },error=>{
        console.log(error);
      }
      );

  }

  guardarDocumentos(){


      console.log(this.documentos.length);

      if(this.documentos.length == 0){

        let ot = {
          orden : this.informe.orden
        }

        this._cS.delDocumentos(ot).subscribe(
          response=>{
            console.log(response.message);
          },error=>{
            console.log(error);
          }
          );
      }

    for (var i = 0; i < this.documentos.length; ++i) {
        
        let datos = {
          orden : this.informe.orden,
          documento : this.documentos[i]
        }

        this._cS.registrarDocumentos(datos).subscribe(
          response=>{

            if(response.code== 200){
              console.log(response.message);
            }else{
              console.log(response.message);
            }

          },error=>{
            console.log(error);
          }
          );


    }

  }

}
