import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import localeEsCo from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCo, 'es-CO');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './componentes/login/login.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { ReportesComponent } from './componentes/reportes/reportes.component';
import { RegistoComponent } from './componentes/registo/registo.component';
import { HistoricoComponent } from './componentes/historico/historico.component';
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { MenuPrincipalComponent } from './componentes/colision/menu-principal/menu-principal.component';
import { MainMenuComponent } from './componentes/main-menu/main-menu.component';
import { TrazabilidadComponent } from './componentes/colision/trazabilidad/trazabilidad.component';
import { CabeceraComponent } from './componentes/colision/cabecera/cabecera.component';
import { GestorAseguradoraComponent } from './componentes/colision/gestor-aseguradora/gestor-aseguradora.component';
import { GestorRepuestosComponent } from './componentes/colision/gestor-repuestos/gestor-repuestos.component';
import { TallerComponent } from './componentes/colision/taller/taller.component';
import { PageErrorComponent } from './componentes/page-error/page-error.component';
import { ComisionComponent } from './componentes/comisiones/comision/comision.component';
import { ConfigComisionesComponent } from './componentes/comisiones/config-comisiones/config-comisiones.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    ReportesComponent,
    RegistoComponent,
    HistoricoComponent,
    UsuariosComponent,
    MenuPrincipalComponent,
    MainMenuComponent,
    TrazabilidadComponent,
    CabeceraComponent,
    GestorAseguradoraComponent,
    GestorRepuestosComponent,
    TallerComponent,
    PageErrorComponent,
    ComisionComponent,
    ConfigComisionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-CO' } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
