import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from './GLOBAL';

@Injectable({
  providedIn: 'root'
})
export class ComisionesService {

	url : string;

  constructor(
  	private _http : Http
  	) {
  		this.url = GLOBAL.url;
  	 }


  	getConfiguracion(id){

  		return this._http.get(this.url+'config-comi/'+id).pipe(map(res=>res.json()));

  	} 

  	getAsesores(){

  		return this._http.get(this.url+'comisionados').pipe(map(res=>res.json()));

  	} 

    getCampos(mes,anio){

      return this._http.get(this.url+'campos/'+mes+'/'+anio).pipe(map(res=>res.json()));

    } 


    getValoresExt(mes,anio){

      return this._http.get(this.url+'datos-comi-ext/'+mes+'/'+anio).pipe(map(res=>res.json()));

    } 

    getValoresInt(mes,anio){

      return this._http.get(this.url+'datos-comi-int/'+mes+'/'+anio).pipe(map(res=>res.json()));

    } 

    getConfigAsesores(id_a,id_c){

      return this._http.get(this.url+'config-comi-asesor/'+id_a+'/'+id_c).pipe(map(res=>res.json()));

    } 

    getConfiguracionAsesor(id){

      return this._http.get(this.url+'get-config-comi/'+id).pipe(map(res=>res.json()));

    } 

    registrarDatos(parametros){

    let json = JSON.stringify(parametros);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

    return this._http.post(this.url+'registro-valor', params, {headers: headers})
             .pipe(map(res => res.json()));

   }

   registrarConfig(parametros){

    let json = JSON.stringify(parametros);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

    return this._http.post(this.url+'registro-config', params, {headers: headers})
             .pipe(map(res => res.json()));

   }

}
