import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../servicios/login.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	public mensaje_info : string;
	public formLog: FormGroup;

  constructor(
  	private formBuilder: FormBuilder,
  	private _lgS : LoginService,
  	private route : Router
  	) {
  		this.mensaje_info = '';
  	 }

  ngOnInit() {
  	this.formLog = this.formBuilder.group({
      user: ['', Validators.required],
      pass: ['', Validators.required]
    });
  }


  logueo(user, pass){

  	this._lgS.logueo(user).subscribe(
  		response=>{

  			if(response.data.length > 0){

  			if(response.data[0].usuario == user && response.data[0].clave == pass){

  				localStorage.setItem('id', response.data[0].id);
  				localStorage.setItem('rol', response.data[0].rol);
  				localStorage.setItem('bodega', response.data[0].bodega);

  				this.route.navigate(['/menu']);
  			}else if(response.data[0].usuario == user && response.data[0].clave != pass){
  				this.mensaje_info = 'Clave incorrecta';
  			}
  		}else{
  			this.mensaje_info = 'Usuario no existe';
  		}

  		},error=>{
  			this.mensaje_info = error;
  		}
  		);

  	return false;

  }

}
