import { Component, OnInit } from '@angular/core';
import { ComisionesService } from '../../../servicios/comisiones.service';

@Component({
  selector: 'app-config-comisiones',
  templateUrl: './config-comisiones.component.html',
  styleUrls: ['./config-comisiones.component.css']
})
export class ConfigComisionesComponent implements OnInit {

	asesores : Array<any>;
	configuraciones : Array<any>;
  campos : Array<any>;
  mes_actual : string;
  anio_actual : string;

  constructor(
  	private _cS : ComisionesService
  	) {

  	this.asesores = [];
  	this.configuraciones = [];
    this.campos = [];
    
   }

  ngOnInit() {

   
  	this.getAsesores();
    this.getCampos();
  }

    getAsesores(){

    this._cS.getAsesores().subscribe(
      res=>{
        console.log(res);
        if(res.code == 200){
          for (var i = 0; i < res.data.length; ++i) {
            this.getConfiguracion(res.data[i].id, res.data[i].nombre);
          }
        }
      },error=>{
        console.log(error);
      });

  }

  getConfiguracion(id_a, asesor){

  	this._cS.getConfiguracion(id_a).subscribe(
  		res=>{
  			if(res.code == 200){

  					this.configuraciones.push(
            { 
              id : id_a,
              asesor : asesor,
              datos : res.data
            });	
           // this.getConfigAsesor(id_a, res.data[i].id, res.data[i].configuracion, asesor);
  			}
  		},error=>{
        console.log(error)
  		});
    console.log(this.configuraciones);
  }

  getConfigAsesor(id_a, id_c, nombre, asesor){

    this._cS.getConfigAsesores(id_a, id_c).subscribe(
      res=>{

            this.configuraciones.push(
              {
                asesor: asesor,
                data :  {
                nombre,
                estado : res.data[0].estado
                }
              });  
          console.log(this.configuraciones);
      },error=>{
        console.log(error)
      });
  }



    getCampos(){

    console.log(this.mes_actual+'--'+this.anio_actual);

    this._cS.getCampos(this.mes_actual, this.anio_actual).subscribe(
      res=>{
        if(res.code == 200){
          for (var i = 0; i < res.data.length; ++i) {
            this.campos.push(res.data[i]);  
          }
        }
      },error=>{
        console.log(error);
      });

  }

  cambio(id_asesor, id, valor){

    let bool = valor;

    if(valor == false)
      bool = 0;

    let datos = {
      id_asesor : id_asesor,
      id_conf : id,
      estado : bool
    }

    this._cS.registrarConfig(datos).subscribe(res=>{

        if(res.code == 200){
          console.log(res.message);
        }else{
          console.log(res.message);
        }

    },error=>{
      console.log(error);
    });
  }

  guardarValor(id, valor, mes, anio){
    

    if(valor == ''){
      console.log('el valor no pued eser vacio');
    }else{

      let dato = {
        id_campo : id,
        valor : valor,
        mes : mes,
        anio : anio
      }

      console.log(dato);

      this._cS.registrarDatos(dato).subscribe(res=>{

        if(res.code == 200){
          console.log(res.message);
        }else{
          console.log(res.message);
        }

      },error=>{
        console.log(error);
      });
    }
  }

  formu(form){
    console.log(form.value);
  }

}
