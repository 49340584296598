import { Component, OnInit } from '@angular/core';
import { RegistroService } from '../../servicios/registro.service';

@Component({
  selector: 'app-registo',
  templateUrl: './registo.component.html',
  styleUrls: ['./registo.component.css']
})
export class RegistoComponent implements OnInit {

	public Data : Array<any>;
  public valor : number;
  public mensaje_info : string;
  public bodega : string;
  public texto_btn : string;
  public edit : boolean;
  constructor(
  	public _regS : RegistroService
  	) 
  { 
  	this.Data = [];
    this.mensaje_info = '';
    this.texto_btn = 'Editar';
    this.edit = false;
  }

  ngOnInit() {

  this.bodega = localStorage.getItem('bodega');
  this.cargarReferencias();

  }

  editar(){

    this.edit = !this.edit;

    if(this.edit)
      this.texto_btn = 'Cancelar Edicion';
      else
      this.texto_btn = 'Editar';

  }

  cargarReferencias(){

      console.log(this.bodega);

  		this._regS.getReferencias(this.bodega).subscribe(
  		response=>{

  			console.log(response);

  			for (var i = 0; i < response.data.length; ++i) {
  				this.Data.push(response.data[i]);
  			}

  		},error=>{
        console.log(error);
  		}
  		);
  }


  registro(cod, bod, val,sto){

    //console.log(cod+'-'+bodega+'-'+val);
    
    let datos = {
      codigo : cod,
      bodega : bod,
      cantidad : val,
      stock : sto,
      usuario : localStorage.getItem('id')
    }

    this._regS.registrarDatos(datos).subscribe(
      response=>{

        console.log(response);
        this.mensaje_info = 'Se registro correctamente la referencia '+cod;
        this.Data = [];
        this.cargarReferencias();

      },error=>{
        console.log(error);
      }
      );

  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
