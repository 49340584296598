import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from './GLOBAL';

@Injectable({
  providedIn: 'root'
})
export class ColisionService {

	private url : string;

    constructor(public  _http : Http) {
  	this.url = GLOBAL.url;
  }



 getOrdenes(){
 	return this._http.get(this.url+'taller').pipe(map(resp=>resp.json()));
 }

 getOrden(orden : string){
 	return this._http.get(this.url+'taller/'+orden).pipe(map(resp=>resp.json()));
 }

 getOrdenSelect(orden : string){
 	return this._http.get(this.url+'taller-select/'+orden).pipe(map(resp=>resp.json()));
 }

 getPedido(orden : string){
 	return this._http.get(this.url+'taller-ped/'+orden).pipe(map(resp=>resp.json()));
 }

 getTraslado(orden : string){
 	return this._http.get(this.url+'taller-tras/'+orden).pipe(map(resp=>resp.json()));
 }

 getFactura(orden : string){
 	return this._http.get(this.url+'taller-fact/'+orden).pipe(map(resp=>resp.json()));
 }

  getEstados(orden? : string){
 	return this._http.get(this.url+'taller-estados').pipe(map(resp=>resp.json()));
 }

 registrarDatos(parametros){

 		let json = JSON.stringify(parametros);
		let params = 'json='+json;
		let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

		return this._http.post(this.url+'infome', params, {headers: headers})
						 .pipe(map(res => res.json()));

 }

 registrarDocumentos(parametros){

 		let json = JSON.stringify(parametros);
		let params = 'json='+json;
		let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

		return this._http.post(this.url+'documentos', params, {headers: headers})
						 .pipe(map(res => res.json()));

 }

 getInformes(orden : string){
 	return this._http.get(this.url+'aseguradoras-taller/'+orden).pipe(map(resp=>resp.json()));
 }

 getDocumentos(orden : string){
 	return this._http.get(this.url+'documentos/'+orden).pipe(map(resp=>resp.json()));
 }



  delDocumentos(parametros){

 		let json = JSON.stringify(parametros);
		let params = 'json='+json;
		let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

		return this._http.post(this.url+'documentos-del', params, {headers: headers})
						 .pipe(map(res => res.json()));

 }

  getOrdenesAseguradora(orden? : string){
 	return this._http.get(this.url+'orden-aseguradora/'+orden).pipe(map(resp=>resp.json()));
 }

 getPedidos(orden : string){
 	return this._http.get(this.url+'pedidos/'+orden).pipe(map(resp=>resp.json()));
 }

 getTraslados(orden : string){
 	return this._http.get(this.url+'traslados/'+orden).pipe(map(resp=>resp.json()));
 }

 registrarRepuestos(parametros){

 		let json = JSON.stringify(parametros);
		let params = 'json='+json;
		let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});

		return this._http.post(this.url+'estado-ped-tras', params, {headers: headers})
						 .pipe(map(res => res.json()));

 }
}
