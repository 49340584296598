import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './componentes/login/login.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { RegistoComponent } from './componentes/registo/registo.component';
import { ReportesComponent } from './componentes/reportes/reportes.component';
import { HistoricoComponent } from './componentes/historico/historico.component';
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { MainMenuComponent } from './componentes/main-menu/main-menu.component';
import { MenuPrincipalComponent } from './componentes/colision/menu-principal/menu-principal.component';
import { TrazabilidadComponent} from './componentes/colision/trazabilidad/trazabilidad.component';
import { CabeceraComponent} from './componentes/colision/cabecera/cabecera.component';
import { GestorAseguradoraComponent } from './componentes/colision/gestor-aseguradora/gestor-aseguradora.component';
import { GestorRepuestosComponent } from './componentes/colision/gestor-repuestos/gestor-repuestos.component';
import { TallerComponent } from './componentes/colision/taller/taller.component';
import { PageErrorComponent } from './componentes/page-error/page-error.component'; 
import { ConfigComisionesComponent } from './componentes/comisiones/config-comisiones/config-comisiones.component';
import { ComisionComponent } from './componentes/comisiones/comision/comision.component';


const routes: Routes = [
{path: '', component: LoginComponent},
{path: 'login', component: LoginComponent},
{path: 'menu', component: MenuComponent},
{path: 'registro', component: RegistoComponent},
{path: 'reportes', component: ReportesComponent},
{path: 'historico', component: HistoricoComponent},
{path: 'usuarios', component: UsuariosComponent},
{path: 'main', component: MainMenuComponent},
{path: 'cabecera', component: CabeceraComponent},
{path: 'colision', component: MenuPrincipalComponent},
{path: 'trazabilidad', component: TrazabilidadComponent},
{path: 'gestor-a', component: GestorAseguradoraComponent},
{path: 'gestor-r', component: GestorRepuestosComponent},
{path: 'taller', component: TallerComponent},
{path: 'configc', component: ConfigComisionesComponent},
{path: 'comisiones', component: ComisionComponent},
{path: '**', component: PageErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
