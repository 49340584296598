import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taller',
  templateUrl: './taller.component.html',
  styleUrls: ['./taller.component.css', '../gestor-repuestos/gestor-repuestos.component.css']
})
export class TallerComponent implements OnInit {

	public latoneria : boolean;
	public superficie : boolean;
	public pintura : boolean;
	public armado : boolean;
	public electromecanica : boolean;
	public alistamiento : boolean;

	public calidad : Array<any>;

  constructor() { 

  	this.latoneria = false;
  	this.superficie = false;
  	this.pintura = false;
  	this.armado = false;
  	this.electromecanica = false;
  	this.alistamiento = false;

  	this.calidad = [
  	'Verificación de trabajos solicitados por el cliente',
  	'Estado limpieza del vehiculo',
  	'Verificación niveles de fluidos',
  	'Verificación sistema iluminación',
  	'Verificación de piezas sustituidas',
  	'Verificación estado Bateria',
  	'Verificación cuadre y rizados en piezas reparadas',
  	'Verificación pintura (Tonalidad y Calidad)',
  	'Verificación reporte de alineación',
  	'Verificación Vehículo fogueado'
  	]

   }

  ngOnInit() {



  }
select_a(event : any, index){
	console.log(event);
	console.log(index);
}


select(event : any, index){
	console.log(event);
	console.log(index);
}


}
