import { Component, OnInit } from '@angular/core';
import { ComisionesService } from '../../../servicios/comisiones.service';

@Component({
  selector: 'app-comision',
  templateUrl: './comision.component.html',
  styleUrls: ['./comision.component.css']
})
export class ComisionComponent implements OnInit {

	asesores : Array<any> = [];

  mes_actual : string = '1';
  ano_actual : string = '2019';

  colision_tul : number;
  colision_pal : number;

  mecanica_tul : number;
  mecanica_pal : number;

  garantia_jac_tul : number;
  garantia_jac_pal : number;

  garantia_kia_tul : number;
  garantia_kia_pal : number;

  constructor(
  	private _cS : ComisionesService
  	) { }

  ngOnInit() {

    this.getAsesores();

    this._cS.getValoresExt(this.mes_actual, this.ano_actual).subscribe(res=>{

        if(res.data[0].centro == 115){

            if(res.data[0].grupo == 'Colision'){
              this.colision_tul = res.data[0].valor;
            }else if(res.data[0].grupo == 'Mecanica'){
               this.mecanica_tul = res.data[0].valor;
            }else if(res.data[0].grupo == 'Garantia KIA'){
               this.garantia_kia_tul = res.data[0].valor;
            }else{
                this.garantia_jac_tul = res.data[0].valor;
            }
        }else{
            if(res.data[0].grupo == 'Colision'){
              this.colision_pal = res.data[0].valor;
            }else if(res.data[0].grupo == 'Mecanica'){
               this.mecanica_pal = res.data[0].valor;
            }else if(res.data[0].grupo == 'Garantia KIA'){
               this.garantia_kia_pal = res.data[0].valor;
            }else{
                this.garantia_jac_pal = res.data[0].valor;
            }
        }

    },error=>{
        console.log(error);
    });
  }


  getDatosInt(){
    
  }

  getAsesores(){
    this._cS.getAsesores().subscribe(res=>{

      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.getConfiguracion(res.data[i].id, res.data[i].nombre)
        }
      }

    },error=>{
      console.log(error);
    });
  }

  getConfiguracion(id, nombre){

      this._cS.getConfiguracionAsesor(id).subscribe(res=>{

      if(res.code == 200){
          this.asesores.push(
              {
                nombre : nombre,
                datos : res.data
              }

            );
         console.log(this.asesores);
      }

    },error=>{
      console.log(error);
    });
  }

}
