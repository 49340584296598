import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from './GLOBAL';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	public url : string;

  constructor(
  	public _http: Http
  	) {
  	this.url = GLOBAL.url;
  	 }


  	 logueo(user : string){

  	 	return this._http.get(this.url+'login/'+user).pipe(map(resp => resp.json()));

  	 }

}
