import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColisionService } from '../../../servicios/colision.service';

@Component({
  selector: 'app-trazabilidad',
  templateUrl: './trazabilidad.component.html',
  styleUrls: ['./trazabilidad.component.css']
})
export class TrazabilidadComponent implements OnInit {

	public orden : string;

	public informacion : Array<any>;
	public pedidos : Array<any>;
	public traslados : Array<any>;
	public facturas : Array<any>;

  constructor(private route : ActivatedRoute, private ruta : Router, private _cS : ColisionService) { 
  	this.orden = '';
  	this.informacion = [];
  	this.pedidos = [];
  	this.traslados = [];
  	this.facturas = [];
   }

 ngOnInit() {
    this.route
        .queryParams
        .subscribe(params => {
            this.orden = params['orden'];
        });


      this._cS.getOrden(this.orden).subscribe(
      	response=>{

      		if(response.code == 200){

      			for (var i = 0; i < response.data.length; ++i) {

      				console.log(response.data[i]);

      				this.informacion.push(response.data[i]);
      			}

      			this._cS.getPedido(this.orden).subscribe(
      				response=>{

      					if(response.code == 200){
      						for (var i = 0; i < response.data.length; ++i) {
      							this.pedidos.push(response.data[i]);
      						}
      					}

      				},error=>{

      				});

      			this._cS.getTraslado(this.orden).subscribe(
      				response=>{

      					if(response.code == 200){
      						for (var i = 0; i < response.data.length; ++i) {
      							this.traslados.push(response.data[i]);
      						}
      					}

      				},error=>{

      				});

      			this._cS.getFactura(this.orden).subscribe(
      				response=>{

      					if(response.code == 200){
      						for (var i = 0; i < response.data.length; ++i) {
      							this.facturas.push(response.data[i]);
      						}
      					}

      				},error=>{

      				});

      		}

      	},error=>{
      		console.log(error);
      	}
      	);



}

atras(){

	this.ruta.navigate(['/colision']);

}


}
